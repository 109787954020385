








import { Vue, Component, Prop } from "vue-property-decorator";
import axios from 'axios';
import { SkuQuestionViewModels } from 'src/js/ViewModels/SkillsUp/SkuQuestionViewModels'

@Component
export default class SkuQuestion extends Vue {
    //prop
    @Prop({ required: true }) value: [];
    @Prop({ required: true }) id: number;
    private question: SkuQuestionViewModels[] = [];
    async getQuestion(): Promise<SkuQuestionViewModels[]> {
        let url = `/SkillsUp/Question?id=${this.id}`
        let response = await axios.get<SkuQuestionViewModels[]>(url);
        return response.data;
    }
    async mounted(): Promise<void> {
        this.question = await this.getQuestion();
    }
}

<template>
    <div>
        <img src="/src/assets/logo.png" />
        <div>{{ message }}</div>
    </div>
  
</template>

<script>
    import Vue from 'vue'
    import Component from 'vue-class-component'

    @Component
    export default class HelloWorld extends Vue {
        // Declared as component data
        message = 'Hello it\'s Hatem!'
    }
</script>
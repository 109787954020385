import Vue from 'vue'
// @ts-ignore
window.Vue = Vue;
//
Vue.component('hello-world', require('./components/HelloWorld.vue').default);


//SonarSkills
Vue.component('ssk-categories', require('./components/SonarSkills/Admin/SskCategories.vue').default);


//SkillsUp
Vue.component('sku-theme', require('./components/SkillsUp/Admin/SkuTheme.vue').default);
Vue.component('sku-answer', require('./components/SkillsUp/Admin/SkuAnswer.vue').default);
Vue.component('sku-question', require('./components/SkillsUp/Admin/SkuQuestion.vue').default);











import { Vue, Component, Prop } from "vue-property-decorator";
import axios from 'axios';
import { SkuThemeViewModels } from 'src/js/ViewModels/SkillsUp/SkuThemeViewModels'

@Component
export default class SkuTheme extends Vue {
    //prop
    @Prop({ required: true }) value: [];
    @Prop({ required: true }) id: number;
    private theme: SkuThemeViewModels[] = [];

    async getTheme(): Promise<SkuThemeViewModels[]> {
        let url = `/SkillsUp/Theme?id=${this.id}`
        let response = await axios.get<SkuThemeViewModels[]>(url);
        return response.data;
    }

    async mounted(): Promise<void> {
        this.theme = await this.getTheme();
    }
}











import { Vue, Component, Prop } from "vue-property-decorator";
import axios from 'axios';
import { SskCategorieViewModel } from 'src/js/ViewModels/SonarSkills/SskCategorieViewModel'

@Component
export default class Categories extends Vue {
    //prop
    @Prop({ required: true }) value: [];
    private categories: SskCategorieViewModel[] = [];
    async getCategories(): Promise<SskCategorieViewModel[]> {
        let url = '/SonarSkills/Categories'
        let response = await axios.get<SskCategorieViewModel[]>(url);
        return response.data;
    }
    async mounted(): Promise<void> {
        this.categories = await this.getCategories();
    }
}

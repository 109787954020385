










import { Vue, Component, Prop } from "vue-property-decorator";
import axios from 'axios';
import { SkuAnswerViewModels } from 'src/js/ViewModels/SkillsUp/SkuAnswerViewModels'

@Component
export default class SkuAnswer extends Vue {
    //prop
    @Prop({ required: true }) value: [];
    @Prop({ required: true }) idQuestion: number;

    private answers: SkuAnswerViewModels[] = [];
    async getAnswers(): Promise<SkuAnswerViewModels[]> {
        let url = `/SkillsUp/Answers?idQuestion=${this.idQuestion}`
        let response = await axios.get<SkuAnswerViewModels[]>(url);
        return response.data;
    }
    async mounted(): Promise<void> {
        this.answers = await this.getAnswers();
    }
}

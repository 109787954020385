import Vue from 'vue'
// @ts-ignore
window.Vue = Vue;

import Multiselect from 'vue-multiselect';
Vue.component('Multiselect', Multiselect);



Vue.config.productionTip = false

